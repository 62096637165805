<template>
  <FullCalendar :options="calendarOptions" />
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";

export default {
  name: "planning-reservation",
  components: {
    FullCalendar,
  },
  props: {
    events: Array,
    slotDuration: {
      type: String,
      default: "00:30:00",
    },
  },
  data() {
    return {
      calendarOptions: {
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          meridiem: false,
        },
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridWeek,timeGridDay",
        },
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        nextDayThreshold: "09:00:00",
        initialView: "timeGridWeek",
        locale: frLocale,
        allDaySlot: false,
        eventMinHeight: 0,
        displayEventTime: false,
        dayHeaderFormat: {
          weekday: "long",
        },
        events: [],
        slotDuration: this.slotDuration,
        eventDidMount: this.eventDidMount,
        eventMouseEnter: this.eventMouseEnter,
        eventMouseLeave: this.eventMouseLeave,
        dayHeaderDidMount: this.dayHeaderDidMount,
        eventClick: this.eventClick,
      },
    };
  },
  methods: {
    eventDidMount(eventInfo) {
      this.$emit("eventDidMount", eventInfo);
    },
    eventMouseEnter(eventInfo) {
      this.$emit("eventMouseEnter", eventInfo);
    },
    eventMouseLeave(eventInfo) {
      this.$emit("eventMouseLeave", eventInfo);
    },
    dayHeaderDidMount(eventInfo) {
      this.$emit("dayHeaderDidMount", eventInfo);
    },
    eventClick(eventInfo) {
      this.$emit("eventClick", eventInfo);
    },
  },
  watch: {
    events: {
      immediate: true,
      handler: function (value) {
        this.calendarOptions.events = value;
      },
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/_variables";

.comp-full-calendar {
  width: auto !important;
}
.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  padding: 0 20px;
}
.fc
  .fc-timegrid-col:not(.fc-timegrid-axis)
  .fc-timegrid-col-frame:not(.bg-fa-middle-gray) {
  background-color: rgba(242, 242, 242, 1) !important;
}
.fc .fc-timegrid-col:not(.fc-timegrid-axis) {
  padding: 0px 7px;
}
.fc .fc-timegrid-col.fc-day-today {
  background: transparent;
}
a.fc-timegrid-event.fc-v-event.fc-event.fc-event-start.fc-event-end.fc-event-future {
  transition: all 0.3s;
  border: none;
}

a.fc-timegrid-event.fc-v-event.fc-event.fc-event-start.fc-event-end.fc-event-future.hover {
  background-color: $doi--color-color4 !important;
}
a.fc-timegrid-event.fc-v-event.fc-event.fc-event-start.fc-event-end.fc-event-future.selected {
  background-color: $doi--color-color4 !important;
  border: none;
}

a.fc-timegrid-event-short.fc-v-event.fc-event {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fc-event-main-frame {
  display: flex;
  align-items: center;
  font-family: $doi--text-font-family2;
}
.fc-event-main-frame:hover {
  cursor: pointer;
}
.fc-event-main-frame > div {
  flex: 1;
}
.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
  font-family: $doi--text-font-family2;
}
.fc-col-header-cell-cushion {
  font-family: $doi--text-font-family4;
  margin-bottom: 15px;
}
.fc-scrollgrid-sync-inner {
  text-align: left;
  padding-left: 10px;
}
.fc-scrollgrid.fc-scrollgrid-liquid {
  border: none;
}
.fc-toolbar-title {
  font-family: $doi--text-font-family2;
}
.event-reserve .fc-event-title {
  color: black;
  font-family: $doi--text-font-family2;
  align-items: center;
}
.event-reserve .fc-event-main-frame,
.event-reserve .fc-event-title-container {
  display: flex;
  align-items: center;
}
.selected .date-field {
  display: block !important;
}
.event-reserve .price-field {
  display: none;
}
.fc .fc-timegrid-slot-minor {
  border: none;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: solid 1px transparent;
}

@media screen and (max-width: $doi-breakpoint-tablet) {
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 780px) {
  .fc-event-main-frame .fc-event-title,
  .fc-event-main-frame .price-field,
  .fc-event-main-frame .date-field {
    font-size: 11px;
  }
  .fc-scrollgrid-sync-inner {
    padding-left: 0;
  }
  .fc-col-header-cell-cushion {
    font-size: 12px;
  }
  .fc-col-header-cell-cushion > .date-field {
    font-size: 22px;
  }
  .fc-button-groupe > button.fc-timeGridWeek-button {
    display: none;
  }
}
</style>
